import React from "react"
import Particles from 'react-particles-js'
import './soeParticles.css'

const RobParticles = () => (
    <Particles
			width="250px"
			height="250px"
			className="membersParticlesWrapper"
      params={{
        particles: {
            number: {
                value: 200,
                density: {
                    enable: false
                }
            },
            size: {
              value: 3,
              random: true,
            },
            color: {
              value: '#517ec4',
            },
            line_linked: {
                enable: true,
                distance: 20,
                opacity: 0.5,
								color:'#517ec4',
            },
            move: {
                speed: 1
            },
            opacity: {
                anim: {
                    enable: true,
                    opacity_min: 0.3,
                    speed: 2,
                    sync: false
                },
                value: 0.8
            }
        },
        polygon: {
          enable: true,
          type: 'inline',
          scale: 1,
          move: {
            radius: 5,
            type: 'path',
          },
          inline: {
            arrangement: 'equidistant',
          },
          draw: {
            enable: true,
          },
          url: '/rob-small.svg',
        },
        retina_detect: true,
        interactivity: {
            events: {
                onhover: {
                    enable: true,
                    mode: "bubble"
                }
            },
            modes: {
                bubble: {
                    size: 4,
                    distance: 30
                }
            }
        }
      }} />
)

export default RobParticles
