import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"
import Layout, { Wrapper, Spacer } from "../components/layout"
import SoeParticles from "../components/soeParticles"
import RobParticles from "../components/robParticles"
import color from "../components/colors"

const AboutUs = () => (
  <Layout>
    <SEO title="About Prime73" />
    <Spacer h="5" />
    <Wrapper>
      {/*<h1>About Prime73</h1>*/}
			<SoeParticles />
			<h2 style={{float: 'left', textAlign: 'left', marginTop: '12rem'}}>Allow me to introduce Prime73,<br />my creators.</h2>
			<Spacer style={{overflow: 'hidden', clear: 'both'}} h="3" />
      <Quote>"Technology, Security and Processes are our passion, digitalization and automation of exam process is our mission. Experience we have, knowledge behind and support of our diverse network, are key factors for developing and publishing platform for secure online exam - SOE."</Quote>
      <p>Prime 73 is a company of engineers, pragmatics, but SiFi lovers, a bit nerdy and agile people, but also pragmatics and methodical, technology and IT security pioneers.</p>

      <Member>
        <RobParticles />
        <MemberScan style={{animationDirection: 'reverse'}}/>
        <MemberScan />
        <MemberImage src="/rob-faca-2.png" />
        <MemberInfo>
          <MemberName>Robert Kuhlig</MemberName>
          <Quote>“Things are only impossible until they are not”</Quote>
          <p>A visionary willing to go above and beyond, gathers the team of right people, follow through and brings ideas to life.</p>
          <p>Robert Kuhlig is the founder of Munich Institute for IT Service Management and a senior lecturer at the Ludwig-Maximilian-Universität München, section for Computer science under Prof. Kranzlmüller.</p>
          <p>He is a recognized expert in the fields of process analysis and optimization, with more than 20 years of professional experience as consultant and auditor in the areas of IT Service Management and IT Security.</p>
          <p>As consultant and auditor, Robert Kuhlig is cooperating with large European companies and is engaged in managing different IT projects for various industries (telecommunications, finance, public administration).  He also advises and supports numerous companies in the practical implementation of modern service and security management processes and systems.</p>
          <p>Robert Kuhlig is considered in Germany as one of the first trainers certified according to ITIL/ISO20000/ISO27000 standards.</p>
        </MemberInfo>
      </Member>

      <Member>
        <MemberInfo>
          <MemberName>Max Peter</MemberName>
          <Quote>“May the force be with you”</Quote>
          <p>Compliance and optimization, provides valuable technical insights so the performance level is brought to maximum and takes care that all regulative standards are fulfilled.</p>
          <p>Max Peter has already successfully founded several IT companies in the field of IT service management, Cloud IAM (Identity and Access Management) and security. As a certified Cloud Security Advisor, ISO/IEC 27000 auditor and platform technology expert, in cooperation with variety of certification organizations, data protection and global companies (IT service provider, finance and public sector), he supports administration of complex IT projects in the area of IAM, (hybrid) Cloud Migration, Cloud Identity +,Security Services and IT Security.</p>
          <p>Max Peter is actively engaged in business associations and business networks. Among other, he is a founding members of the worldwide business organization German Chapter of EO (Entrepreneur Organization), founder and former President of the International Microsoft Partner Network IAMCP Germany e.V., Former Member of the IAMCP EMEA/International Board and since 2001 Member of the international Microsoft Partner Advisory Council (WPC PEB and IPAC). Max Peter is also engaged in several other associations such as BITKOM, BayME, Munich Network and the D21.</p>
        </MemberInfo>
      </Member>

      <Member>
        <MemberInfo>
          <MemberName>Bernd Moeske</MemberName>
          <Quote>“Live long and prosper”</Quote>
          <p>Pragmatism followed by structure and logic, givs clear guidelines to successful implementation of defined activities.</p>
          <p>Bernd Moeske is a high level management consultant, who successfully supports international customers from various industries (telecommunications, public administration, healthcare, banking and finance, automotive, transportation, research, product development, software, etc.). He has a high working capacity, a positive and constructive attitude and is an experienced mediator.</p>
          <p>Bernd Moeske’s field of expertise is covering four areas:</p>
          <ul>
            <li>Implementation of corporate strategies - EA (Enterprise Architecture) Consulting with a special focus on cooperation between the divisions (strategic, operational) and functions (staff, line, project).</li>
            <li>Auditing and mentoring for IT management, IT architecture with establishing new methods (such as ATAM - Architecture Tradeoff Analysis Method) or tools and optimizing existing functions such as information architecture, application architecture and software development.</li>
            <li>Trainer and coach for agile methods: given the experience in agile project management as developer, Scrum Master and Product Owner, he holds SCRUM certification courses and supports companies with adequate implementation of agile project methodology.</li>
            <li>Selection, adaptation and integration of architecture tools: supporting organizations in finding the optimal architecture (modelling) tools, suitable to their needs. He holds courses for BPMN, Archimate, UML and Sparx Enterprise Architect.</li>
          </ul>
        </MemberInfo>
      </Member>

      <Member>
        <MemberInfo>
          <MemberName>Nemanja Milivojevic</MemberName>
          <Quote>“Beam me up, Scotty!”</Quote>
          <p>Techy who makes ideas viable, meets the challenge, finds solution, implements ideas and delivers products.</p>
        </MemberInfo>
      </Member>
      <Link to="/">Go back to Overview</Link>
    </Wrapper>
  </Layout>
)

export default AboutUs

const Member = styled.div`
  padding: 2rem;
  margin: 3rem 0;
  overflow: hidden;
  position: relative;
  padding-bottom: 250px;
`
const MemberName = styled.h2`
  text-align: center;
`
const MemberInfo = styled.div`
  position: relative;
  top: 250px;
`
const Quote = styled.p`
  font-size: 1.25rem;
  margin-bottom: 2rem 0;
  text-align: center;
`
const MemberScan = styled.div`
  width: 250px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-125px);
  animation: scan 4s alternate;
  animation-fill-mode: forwards;
  border: 3px solid ${color.primaryLight};
`
const MemberImage = styled.img`
  width: 250px;
  height: 250px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-125px);
  animation: memberAppear 3s 2.5s;
  animation-fill-mode: forwards;
  opacity: 0;
  display: inline-block;
  border: 1px solid ${color.light};
  border-radius: 125px;
`
